html {
	background: $html-background-color;
}

html, body {
	margin: 0;
	padding: 0;
}

body {
	font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
	color: $brand-color;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

img {
	max-width: 100%;
	height: auto;
}

h1 {
	font-weight: 300;
	font-size: 2.3rem;
	margin: 0;
}

h2 {
	font-weight: 300;
	font-size: 2.2rem;
	margin: 0 0 30px 0;
}

h3 {
	font-size: 1.8rem;
	margin: 0 0 20px 0;
}

h4 {
	font-size: 1.5rem;
	margin: 0 0 10px 0;
}

p, address, ul, ol, pre {
	margin: 0 0 20px 0;
}

p, address, ul, ol {
	font-size: 1.38rem;
	color: #666;
	font-weight: 300;
	line-height: 1.6;
}

pre {
	padding: 10px;
	background: #eee;
	border-radius: 2px;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
	font-size: 1.2rem;
	color: #666;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote:after {
	content: no-close-quote;
}

blockquote p {
  display: inline;
}

blockquote cite {
	color: #666;
	font-size: 1rem;
	display: block;
	margin-top: 5px;
	text-align: right;
}

blockquote cite:before {
	content: "\2014 \2009";
}
